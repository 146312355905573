import { useEffect, useState } from 'react';
import { interface_translate } from '../texts/interface';
import useCommon from '../hooks/useCommon';
import { v4 as uuidv4 } from 'uuid';
import styles from './ListAttrMedia.module.css';

const ListAttrMedia = (props) => {
  const { language } = useCommon();
  const { setSelectedElement, deleteSelectedElement, defaultElements } = props;

  const [blocksId, setBlocksId] = useState([]);
  const [selectedElements, setSelectedElements] = useState({});


  const addElement = (def_id, def_element) => {
    let element = def_element || null;
    let id = def_id || uuidv4();
    setSelectedElement({ [id]: element });
    setBlocksId((prevData) => [...prevData, id]);
  };

  useEffect(() => {
    if (!defaultElements) return;
    for (const element of defaultElements) {
      const id = uuidv4();
      addElement(id, element);
    }
  }, []);

  const handleFileChange = (id, event) => {
    const files = Array.from(event.target.files);
    setSelectedElement({ [id]: files });
    setSelectedElements((prevElements) => ({ ...prevElements, [id]: files }));
  };

  const handleRemoveFile = (blockId, fileIndex) => {
    setSelectedElements((prevElements) => {
      const updatedFiles = prevElements[blockId].filter((_, i) => i !== fileIndex);
      setSelectedElement({ [blockId]: updatedFiles });
      return { ...prevElements, [blockId]: updatedFiles };
    });
  };

  const isImageFile = (file) => {
    return file.type.startsWith('image/');
  };

  return (
    <div className={styles.listAttr}>
      {blocksId.map((id) => (
        <div className={styles.attr} id={id} key={id}>
          <input
            type="file"
            multiple
            onChange={(e) => handleFileChange(id, e)}
          />
          <div className={styles.selectedFilesGrid}>
            {selectedElements[id] &&
              selectedElements[id].map((file, index) => (
                <div key={index} className={styles.fileItem}>
                  {isImageFile(file) ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    />
                  ) : (
                    <p>{file.name}</p>
                  )}
                  <button onClick={() => handleRemoveFile(id, index)}>
                    {interface_translate['Delete'][language]}
                  </button>
                </div>
              ))}
          </div>
        </div>
      ))}
      <button className={styles.addBtn} onClick={() => addElement()}>
        {interface_translate['Add'][language]}
      </button>
    </div>
  );
};

export default ListAttrMedia;
