import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory.jsx';
import useCommon from '../../hooks/useCommon.jsx';
import ModelView from '../../components/models_view/ModelView.jsx';
import roleAccess from '../../roleAccess.js';
import productService from '../../services/product.js';
import orderService from '../../services/order.js';
import useViewSettings from '../../hooks/useViewSettings.jsx';
import { statuses_translate } from '../../texts/statuses.js';
import { interface_translate } from '../../texts/interface.js';
import { types } from '../../texts/types.js';
import styles from './ProductsPage.module.css';
import '../../App.css';
import deliveryProductService from '../../services/delivery_product.js';

async function delete_product(id, setGlobalError, language) {
  const result = await productService.delete(id, setGlobalError, language);
  return result;
}

async function get_product_statuses() {
  const statuses = await productService.get_statuses();
  return statuses;
}

 async function get_product_for_display(product, language) {
  product.supplier_name = product.supplier?.name;
  product.status_translate = statuses_translate[product.status][language];
  product.storehouse_id = product.storage_cell
    ? product.storage_cell.shelving.storehouse.id
    : '';
  product.storehouse_name = product.storage_cell
    ? product.storage_cell.shelving.storehouse.name
    : '';
  product.storage_cell_name = product.storage_cell
    ? `${product.storage_cell.shelving.name}${product.storage_cell.number}`
    : '';
  const order = await orderService.get(product.order_id);
  product.client_name = order.client.name;
  return product;
}

const table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'product id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'order_id',
    name_for_display: 'order',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'name',
    name_for_display: 'name',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'status_translate',
    name_for_display: 'status',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'delivery_qty_with_unit',
    name_for_display: 'qty',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'price',
    name_for_display: 'price',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'currency',
    name_for_display: 'currency',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storehouse_name',
    name_for_display: 'storehouse',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storage_cell_name',
    name_for_display: 'storage cell',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'supplier_name',
    name_for_display: 'supplier',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const ProductsPage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const navigate = useNavigate();
  const location = useLocation();
  const { clearHistoryPages, pushPage } = usePageMovementHistory();
  const { allViewSettings, setAllViewSettings } = useViewSettings();
  const viewSettings = allViewSettings['received_products'];
  const [filterData, setFiltersData] = useState(
    viewSettings['filterData'] || {}
  );
  const [showFilters, setShowFilters] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    size: viewSettings['size'],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [deliveryProducts, setDeliveryProducts] = useState([]);
  const [productStatuses, setProductStatuses] = useState([]);
  const [selectedModelIds, setSelectedModelIds] = useState([]);
  const [tableAttrs, setTableAttrs] = useState([]);

  useEffect(() => {
    const req = async () => {
      const productStatuses = await get_product_statuses();
      setProductStatuses(productStatuses);
    };
    req();
    clearHistoryPages();
    pushPage(location.pathname);
    const table_attrs = table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions[
          'Received products'
        ].read_all.includes(attr_info.name)
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setTableAttrs(table_attrs);
  }, []);

  useEffect(() => {}, [language]);

  useEffect(() => {
    setAllViewSettings({
      products: { size: paginationSettings['size'], filterData: filterData },
    });
  }, [paginationSettings, filterData]);


  const requestGetProducts = async () => {
    const new_delivery_products_paginated = 
      await deliveryProductService.get_by_filters({},paginationSettings);
    const new_delivery_products = new_delivery_products_paginated.data;
    const new_delivery_products_for_display = [];
    for (let delivery_product of new_delivery_products) {
      delivery_product = await get_product_for_display(
        delivery_product.product, 
        language
      );
      new_delivery_products_for_display.push(delivery_product);
    }
    setTotalPages(new_delivery_products_paginated.total);
    setDeliveryProducts(new_delivery_products_for_display);
    setCurrentPage(paginationSettings['page']);
  };

  const addFiltersData = (data) => {
    setFiltersData({ ...filterData, ...data });
  };

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSetPaginationSettings = (settings) => {
    setPaginationSettings({ ...paginationSettings, ...settings });
  };

  useEffect(() => {
    requestGetProducts();
  }, [paginationSettings]);

  const deleteModels = async () => {
    setDeliveryProducts(
      deliveryProducts.filter((model) => !selectedModelIds.includes(model.id))
    );
    for (const id of selectedModelIds) {
      const result = await delete_product(id, setGlobalError, language);
      if (!result) {
        requestGetProducts();
      }
    }
  };

  return (
    <div>
      <h1>{interface_translate['Received products'][language]}</h1>
      <ModelView
        tableAttrs={tableAttrs}
        models={deliveryProducts}
        setModels={setDeliveryProducts}
        setSelectedModels={setSelectedModelIds}
        setPaginationSettings={handleSetPaginationSettings}
        currentPage={currentPage}
        totalPages={totalPages}
        currentSize={paginationSettings['size']}
      />
    </div>
  );
};

export default ProductsPage;
