import React, { useState, useEffect } from "react";
import ControlledInput from "../../components/ControlledInput";
import userService from "../../services/user";
import { interface_translate } from '../../texts/interface';
import { api_error_translate } from '../../texts/error';
import useCommon from '../../hooks/useCommon';
import styles from '../../components/auth/shered.module.css';
import * as roles from '../../roles';

const UserEditForm = ({ user, onClose, requestGetUsers }) => {
  const { language, setGlobalError, setGlobalMsg } = useCommon();
  const [formData, setFormData] = useState({
    ...user,
    phone_number: user.phone_number || '',
    link_to_messenger: user.link_to_messenger || '',
    bitrix_id: user.bitrix_id || ''
  });
  const [errors, setErrors] = useState({});

  // Обновление формы при смене пользователя
  useEffect(() => {
    setFormData({
      ...user,
      phone_number: user.phone_number || '',
      link_to_messenger: user.link_to_messenger || '',
      bitrix_id: user.bitrix_id || ''
    });
    setErrors({});
  }, [user]);

  const rolesList = Object.entries(roles).map(([key, value]) => ({
    key,
    value
  }));

  const getRoleTranslation = (roleKey) => {
    return interface_translate?.[roleKey]?.[language] || roleKey;
  };

  const handleSave = async (event) => {
    event.preventDefault();
    
  
    try {
      const result = await userService.update(formData.id, formData);
      
      
      if (result?.message) {
        setGlobalMsg(interface_translate['Data updated']?.[language]);
        requestGetUsers();
        onClose();
      }
    } catch (error) {
      console.error('Update error details:', {
        message: error.message,
        stack: error.stack
      });
      const errorMessage = error.message || interface_translate['Failed to update']?.[language];
      setErrors({ global: errorMessage });
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSave}>
      <h2>{interface_translate['Edit User']?.[language]}</h2>
      {errors.global && <div className="error">{errors.global}</div>}
      
      <div className={styles.formFields}>
        <div className={styles.formField}>
          <label htmlFor="email">
            {interface_translate['Email']?.[language]}:
          </label>
          <input
            type="email"
            id="email"
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          {errors.email && (
            <div className="openingBlockErrorAttr">
              {api_error_translate?.[errors.email[0]]?.[language]}
            </div>
          )}
        </div>

        <div className={styles.formField}>
          <label htmlFor="surname">
            {interface_translate['Surname']?.[language]}:
          </label>
          <input
            type="text"
            id="surname"
            value={formData.surname || ""}
            onChange={(e) => setFormData({ ...formData, surname: e.target.value })}
          />
          {errors.surname && (
            <div className="openingBlockErrorAttr">
              {api_error_translate?.[errors.surname[0]]?.[language]}
            </div>
          )}
        </div>

        <div className={styles.formField}>
          <label htmlFor="name">
            {interface_translate['First Name']?.[language]}:
          </label>
          <input
            type="text"
            id="name"
            value={formData.name || ""}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          {errors.name && (
            <div className="openingBlockErrorAttr">
              {api_error_translate?.[errors.name[0]]?.[language]}
            </div>
          )}
        </div>

        <div className={styles.formField}>
          <label htmlFor="patronymic">
            {interface_translate['Patronymic']?.[language]}:
          </label>
          <input
            type="text"
            id="patronymic"
            value={formData.patronymic || ""}
            onChange={(e) => setFormData({ ...formData, patronymic: e.target.value })}
          />
          {errors.patronymic && (
            <div className="openingBlockErrorAttr">
              {api_error_translate?.[errors.patronymic[0]]?.[language]}
            </div>
          )}
        </div>

        <div className={styles.formField}>
          <label htmlFor="phone_number">
            {interface_translate['Phone Number']?.[language]}:
          </label>
          <input
            type="text"
            id="phone_number"
            value={formData.phone_number || ""}
            onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
          />
          {errors.phone_number && (
            <div className="openingBlockErrorAttr">
              {api_error_translate?.[errors.phone_number[0]]?.[language]}
            </div>
          )}
        </div>

        <div className={styles.formField}>
          <label htmlFor="link_to_messenger">
            {interface_translate['Link to messenger']?.[language]}:
          </label>
          <input
            type="text"
            id="link_to_messenger"
            value={formData.link_to_messenger || ""}
            onChange={(e) => setFormData({ ...formData, link_to_messenger: e.target.value })}
          />
        </div>

        <div className={styles.formField}>
          <label htmlFor="bitrix_id">
            {interface_translate['Bitrix ID']?.[language]}:
          </label>
          <input
            type="text"
            id="bitrix_id"
            value={formData.bitrix_id || ""}
            onChange={(e) => setFormData({ ...formData, bitrix_id: e.target.value })}
          />
        </div>
        
        <div className={styles.formField}>
          <label htmlFor="raw_password">
            {interface_translate['Password']?.[language]}:
          </label>
          <input
            type="password"
            id="raw_password"
            value={formData.raw_password || ''}
            onChange={(e) => setFormData({ ...formData, raw_password: e.target.value })}
          />
          {errors.raw_password && (
            <div className="error">
              {api_error_translate?.[errors.raw_password[0]]?.[language] || errors.raw_password}
            </div>
          )}
          <small className={styles.help_text}>
            {interface_translate['Leave empty to keep current password']?.[language]}
          </small>
        </div>

        <div className={styles.formField}>
          <label htmlFor="role">
            {interface_translate['Role']?.[language]}:
          </label>
          <select
            id="role"
            value={formData.role || ""}
            onChange={(e) => setFormData({ ...formData, role: e.target.value })}
          >
            {rolesList.map(({ key, value }) => (
              <option key={key} value={value}>
                {getRoleTranslation(key)}
              </option>
            ))}
          </select>
          {errors.role && (
            <div className="openingBlockErrorAttr">
              {api_error_translate?.[errors.role[0]]?.[language]}
            </div>
          )}
        </div>
      </div>

      <div className={styles.formButtons}>
        <button type="submit">
          {interface_translate['Save']?.[language]}
        </button>
        <button type="button" onClick={onClose}>
          {interface_translate['Cancel']?.[language]}
        </button>
      </div>
    </form>
  );
};

export default UserEditForm;
