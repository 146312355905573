import HeaderSection from './HeaderSection';
import { useState } from 'react';
import {
  FaBars,
  FaUser,
  FaShoppingCart,
  FaBox,
  FaDolly,
  FaTruck,
  FaWarehouse,
} from 'react-icons/fa';
import useCommon from '../../hooks/useCommon';
import roleAccess from '../../roleAccess';
import { interface_translate } from '../../texts/interface';
import styles from './Header.module.css';

const sections_description = [
  { name: 'Counterparties', img: <FaUser />, path: '/counterparties' },
  { name: 'Orders', img: <FaShoppingCart />, path: '/orders' },
  { name: 'Products', img: <FaShoppingCart />, path: '/products' },
  { name: 'Deliveries', img: <FaBox />, path: '/deliveries' },
  {
    name: 'Unidentified deliveries',
    img: <FaBox />,
    path: '/unidentified_deliveries',
  },
  {
    name: 'Received products',
    img: <FaBox />,
    path: '/received_products',
  },
  { name: 'Shipments', img: <FaTruck />, path: '/shipments' },
  { name: 'Customer cargo list', img: <FaDolly />, path: '/customer_cargo' },
  {
    name: 'Unidentified cargo list',
    img: <FaDolly />,
    path: '/unidentified_cargo',
  },
  { name: 'Storehouses', img: <FaWarehouse />, path: '/storehouses' },
  { name: 'Users', img: <FaUser />, path: '/users' },
];

const HeaderSections = () => {
  const { language, userRole } = useCommon();

  const [isOpen, setIsOpen] = useState(false);
  const [sectionNameIsActive, setSectionNameIsActive] = useState(
    sections_description[0]
  );

  const allowed_sections = Object.entries(
    roleAccess[userRole]?.permissions || {}
  )
    .filter(([key, value]) => value.read_all)
    .map((v) => v[0]);
  const sections = sections_description.filter((m) =>
    allowed_sections.includes(m.name)
  );

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className={styles.burgerMenu}
        onClick={toggleMenu}
      >
        <FaBars />
      </div>
      <ul
        className={`${styles.burgerMenuSectionList} ${
          isOpen ? styles.open : ''
        }`}
      >
        {sections.map((section, i) => {
          return (
            <li
              key={i}
              className={`${styles.section}`}
            >
              <HeaderSection
                name={interface_translate[section.name][language]}
                path={section.path}
              >
                {section.img}
                <span
                  className={styles.sectionName}
                  dangerouslySetInnerHTML={{
                    __html: interface_translate[section.name][language],
                  }}
                />
              </HeaderSection>
            </li>
          );
        })}
      </ul>
      <ul className={styles.sectionList}>
        {sections.map((section, i) => {
          return (
            <li
              key={i}
              className={`${styles.section} ${
                sectionNameIsActive === section.name ? styles.sectionActive : ''
              }`}
            >
              <HeaderSection
                name={interface_translate[section.name][language]}
                path={section.path}
                setSectionNameIsActive={setSectionNameIsActive}
              >
                {section.img}
                <span
                  className={styles.sectionName}
                  dangerouslySetInnerHTML={{
                    __html: interface_translate[section.name][language],
                  }}
                />
              </HeaderSection>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default HeaderSections;
