import { useState, useEffect } from 'react';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import ModelView from '../../components/models_view/ModelView.jsx';
import ModelsControls from '../../components/models_view/ModelsControls.jsx';
import ModelFilters from '../../components/ModelFilters.jsx';
import SelectFilter from '../../components/filters/SelectFilter.jsx';
import AutocompleteFilter from '../../components/filters/AutocompleteFilter.jsx';
import roleAccess from '../../roleAccess.js';
import shipmentService from '../../services/shipment.js';
import userService from '../../services/user.js';
import orderService from '../../services/order.js';
import useViewSettings from '../../hooks/useViewSettings.jsx';
import { statuses_translate } from '../../texts/statuses.js';
import { types } from '../../texts/types.js';
import { interface_translate } from '../../texts/interface.js';
import styles from './ShipmentsPage.module.css';
import '../../App.css';
import ModalWindow from '../../components/ModalWindow.jsx';
import { api_error_translate } from '../../texts/error.js';
import { useLocation } from 'react-router-dom';

async function delete_shipment(
  id,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await shipmentService.delete(
    id,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function shipment_add_info(
  id,
  data,
  setErrors,
  resetGlobalMsg,
  language
) {
  const result = await shipmentService.add_info_about_assembling(
    id,
    data,
    setErrors,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_users(filters = {}, size = 50) {
  const usersPaginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return usersPaginated.data;
}

async function get_orders(filters = {}, size = 50) {
  const ordersPaginated = await orderService.get_by_filters(filters, {
    size: size,
  });
  return ordersPaginated.data;
}

async function get_shipment_packing() {
  const packing = await shipmentService.get_packing();
  return packing;
}

async function get_shipment_statuses() {
  const statuses = await shipmentService.get_statuses();
  return statuses;
}

const table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'shipment id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'datetime_created',
    name_for_display: 'datetime created',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'client_name',
    name_for_display: 'client',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'type_logistics_translate',
    name_for_display: 'type logistics',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'status_translate',
    name_for_display: 'status',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'invoice_number',
    name_for_display: 'cargo number',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'responsible_names',
    name_for_display: 'responsible',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'add_information',
    name_for_display: 'add information',
    translate_name_for_display: null,
    use_sort: false, 
  },
];

const ShipmentsPage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const location = useLocation();
  const { clearHistoryPages, pushPage } = usePageMovementHistory();
  const { allViewSettings, setAllViewSettings } = useViewSettings();
  const viewSettings = allViewSettings['shipments'] || {};
  const [filterData, setFiltersData] = useState(
    viewSettings['filterData'] || {}
  );
  const [showFilters, setShowFilters] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    size: viewSettings['size'] || 10,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [shipments, setShipments] = useState([]);
  const [shipmentPacking, setShipmentPacking] = useState([]);
  const [shipmentStatuses, setShipmentStatuses] = useState([]);
  const [selectedModelIds, setSelectedModelIds] = useState([]);
  const [errors, setErrors] = useState({});
  const [sortData, setSortData] = useState({});
  const [tableAttrs, setTableAttrs] = useState([]);
  const [viewShipmentAddInfoModal, setViewShipmentAddInfoModal] = useState(false);
  const [shipmentAddInfoData, setShipmentAddInfoData] = useState({});
  const [shipmentAddInfoErrors, setShipmentAddInfoErrors] = useState({});

  useEffect(() => {
    const req = async () => {
      const shipmentPacking = await get_shipment_packing();
      setShipmentPacking(shipmentPacking);
      const shipmentStatuses = await get_shipment_statuses();
      setShipmentStatuses(shipmentStatuses);
    };
    req();
    clearHistoryPages();
    pushPage(location.pathname);
    const table_attrs = table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions.Shipments.read_all.includes(
          attr_info.name
        )
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setTableAttrs(table_attrs);
  }, [language, userRole]);

  useEffect(() => {
    setTableAttrs((prevAttrs) =>
      prevAttrs.map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }))
    );
  }, [language]);

  useEffect(() => {
    for (let shipment of shipments) {
      shipment.status_translate = statuses_translate[shipment.status][language];
      shipment.packing_translate = types[shipment.packing]?.[language] || '';
      shipment.type_logistics_translate = shipment.type_logistics
        ? types[shipment.type_logistics][language]
        : null;
    }
    setShipments([...shipments]);
  }, [language]);

  useEffect(() => {
    setAllViewSettings({
      shipments: { size: paginationSettings['size'], filterData: filterData },
    });
  }, [paginationSettings, filterData]);

  useEffect(() => {
    const newSortData = { ...sortData };
    if (sortData.by === 'status_translate') {
      newSortData.by = 'status';
    } else if (sortData.by === 'type_logistics_translate') {
      newSortData.by = 'type_logistics';
    }
    const newPaginationSettings = {
      ...paginationSettings,
      sort_by: newSortData.by,
      sort_order: newSortData.order,
    };
    setPaginationSettings(newPaginationSettings);
  }, [sortData]);

  const mapFilterData = () => {
    const data = { ...filterData };
    if (filterData.order) data['order_id'] = filterData.order.id;
    if (!filterData.type_logistics || filterData.type_logistics === '') {
      delete data['type_logistics'];
    }
    if (!filterData.status || filterData.status === '') {
      delete data['status'];
    }
    if (!filterData.packing || filterData.packing === '') {
      delete data['packing'];
    }
    if (filterData.responsible)
      data['responsible_id'] = filterData.responsible.id;
    if (filterData.co_executors)
      data['co_executor_id'] = filterData.co_executors.id;
    delete data['order'];
    delete data['responsible'];
    delete data['co_executors'];
    return data;
  };

  const requestGetShipments = async () => {
    const filter = mapFilterData();
    const new_shipments_paginated = await shipmentService.get_by_filters(
      filter,
      paginationSettings
    );
    const new_shipments = new_shipments_paginated.data;

    for (let shipment of new_shipments) {
      shipment.status_translate = statuses_translate[shipment.status][language];
      shipment.type_logistics_translate = shipment.type_logistics
        ? types[shipment.type_logistics][language]
        : null;
      shipment.datetime_created = new Date(
        shipment.datetime_created
      ).toLocaleDateString();

      if (shipment.responsible) {
        let responsible_names = shipment.responsible.map((user) => user.email);
        const uniqueResponsibleNames = new Set(responsible_names);
        shipment.responsible_names = Array.from(uniqueResponsibleNames).join(', ');
      }

      shipment.client_name = shipment.order?.client?.name || '';

      // Добавляем кнопку "Добавить информацию" для отправлений со статусом 'created'
      if (shipment.status === 'created') {
        shipment.add_information = (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setViewShipmentAddInfoModal(true);
              setShipmentAddInfoData({
                shipment_id: shipment.id,
              });
            }}
          >
            {interface_translate['Add information'][language]}
          </button>
        );
      } else {
        shipment.add_information = null; // Для остальных статусов не показываем кнопку
      }
    }

    const totalItems = new_shipments_paginated.total;
    const itemsPerPage = paginationSettings.size;

    setTotalPages(Math.ceil(totalItems / itemsPerPage));
    setShipments(new_shipments);
    setCurrentPage(paginationSettings.page);
  };

  const addFiltersData = (attr, value) => {
    if (value) {
      setFiltersData({ ...filterData, [attr]: value });
    } else if (filterData.hasOwnProperty(attr)) {
      delete filterData[attr];
      setFiltersData({ ...filterData });
    }
  };

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSetPaginationSettings = (settings) => {
    setPaginationSettings({ ...paginationSettings, ...settings });
  };

  useEffect(() => {
    requestGetShipments();
  }, [paginationSettings]);

  const deleteModels = async () => {
    setShipments(
      shipments.filter((model) => !selectedModelIds.includes(model.id))
    );
    for (const id of selectedModelIds) {
      const result = await delete_shipment(
        id,
        setErrors,
        setGlobalError,
        resetGlobalMsg,
        language
      );
      if (!result) {
        requestGetShipments();
      }
    }
  };

  const getShipmentAddInfoDataFromAttr = () => {
    const data = {
      ...shipmentAddInfoData,
    };
    return data;
  };

  return (
    <div>
      <ModelsControls
        section="Shipments"
        model_name={interface_translate['Shipments'][language]}
        filtersActivated={Object.keys(filterData).length > 0}
        toggleShowFilters={toggleShowFilters}
        requestModels={requestGetShipments}
        deleteModels={deleteModels}
      />
      {showFilters && (
        <ModelFilters
          requestModels={requestGetShipments}
          resetFilterData={() => setFiltersData({})}
        >
          <AutocompleteFilter
            title_name={interface_translate['Order'][language]}
            attr="order"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['order'] !== undefined ? filterData['order'] : null
            }
            getElementLabel={(option) => option.id.toString()}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['order'] = value;
              const orders = await get_orders(filters);
              return orders;
            }}
          />
          <SelectFilter
            title_name={interface_translate['Type logistics'][language]}
            attr="type_logistics"
            select_names={[
              '',
              types['cargo'][language],
              types['white'][language],
            ]}
            select_attr={['', 'cargo', 'white']}
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['type_logistics'] !== undefined
                ? filterData['type_logistics']
                : ''
            }
          />
          <SelectFilter
            title_name={interface_translate['Packing'][language]}
            attr="packing"
            select_names={[
              '',
              ...shipmentPacking.map((m) => types[m]?.[language] || m),
            ]}
            select_attr={['', ...shipmentPacking]}
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['packing'] !== undefined ? filterData['packing'] : ''
            }
          />
          <SelectFilter
            title_name={interface_translate['Status'][language]}
            attr="status"
            select_names={[
              '',
              ...shipmentStatuses.map((m) => statuses_translate[m][language]),
            ]}
            select_attr={['', ...shipmentStatuses]}
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['status'] !== undefined ? filterData['status'] : ''
            }
          />
          <AutocompleteFilter
            title_name={interface_translate['Responsible'][language]}
            attr="responsible"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['responsible'] !== undefined
                ? filterData['responsible']
                : null
            }
            getElementLabel={(option) => option.email}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['email'] = value;
              const responsible = await get_users(filters);
              return responsible;
            }}
          />
          <AutocompleteFilter
            title_name={interface_translate['Co executors'][language]}
            attr="co_executors"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['co_executors'] !== undefined
                ? filterData['co_executors']
                : null
            }
            getElementLabel={(option) => option.email}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['email'] = value;
              const coExecutors = await get_users(filters);
              return coExecutors;
            }}
          />
        </ModelFilters>
      )}
      {viewShipmentAddInfoModal && (
        <ModalWindow
          isActive={viewShipmentAddInfoModal}
          setIsActive={(v) => {
            setShipmentAddInfoData({});
            setViewShipmentAddInfoModal(v);
          }}
        >
          <div className="modalWindowDefaultContainer">
            <div className={styles.controlBtns}>
              <button
                onClick={async () => {
                  const data = getShipmentAddInfoDataFromAttr();
                  if (!data) return;
                  const result = await shipment_add_info(
                    shipmentAddInfoData.shipment_id,
                    data,
                    setShipmentAddInfoErrors,
                    resetGlobalMsg,
                    language
                  );
                  if (result) {
                    setGlobalMsg(interface_translate['Data added'][language]);
                    resetGlobalError();
                    setErrors({});
                    requestGetShipments();
                    setViewShipmentAddInfoModal(false);
                    setShipmentAddInfoData({});
                  }
                }}
              >
                {interface_translate['Save'][language]}
              </button>
              <button
                onClick={() => {
                  setShipmentAddInfoData({});
                  setViewShipmentAddInfoModal(false);
                }}
              >
                {interface_translate['Exit'][language]}
              </button>
            </div>
            <div className="openingBlockAttr">
              <p className="openingBlockAttrName">
                {interface_translate['Weight in kg'][language]}
              </p>
              <input
                type="text"
                value={shipmentAddInfoData.weight_in_kg || ''}
                onChange={(e) => {
                  setShipmentAddInfoData({
                    ...shipmentAddInfoData,
                    weight_in_kg: e.target.value,
                  });
                }}
              />
            </div>
            {shipmentAddInfoErrors['weight_in_kg'] && (
              <div className="openingBlockErrorAttr">
                {
                  api_error_translate[shipmentAddInfoErrors['weight_in_kg'][0]][
                    language
                  ]
                }
              </div>
            )}
            <div className="openingBlockAttr">
              <p className="openingBlockAttrName">
                {interface_translate['Volume in м³'][language]}
              </p>
              <input
                type="text"
                value={shipmentAddInfoData.volume_in_m3 || ''}
                onChange={(e) => {
                  setShipmentAddInfoData({
                    ...shipmentAddInfoData,
                    volume_in_m3: e.target.value,
                  });
                }}
              />
            </div>
            {shipmentAddInfoErrors['volume_in_m3'] && (
              <div className="openingBlockErrorAttr">
                {
                  api_error_translate[shipmentAddInfoErrors['volume_in_m3'][0]][
                    language
                  ]
                }
              </div>
            )}
            <div className="openingBlockAttr">
              <div className="openingBlockAttrName">
                {interface_translate['Number of seats'][language]}
              </div>
              <input
                type="text"
                value={shipmentAddInfoData.number_of_seats || ''}
                onChange={(e) => {
                  setShipmentAddInfoData({
                    ...shipmentAddInfoData,
                    number_of_seats: e.target.value,
                  });
                }}
              />
            </div>
            {shipmentAddInfoErrors['number_of_seats'] && (
              <div className="openingBlockErrorAttr">
                {
                  api_error_translate[
                    shipmentAddInfoErrors['number_of_seats'][0]
                  ][language]
                }
              </div>
            )}
          </div>
        </ModalWindow>
      )}
      <ModelView
        tableAttrs={tableAttrs}
        models={shipments}
        setSelectedModels={setSelectedModelIds}
        sortData={sortData}
        setSortData={setSortData}
        setPaginationSettings={handleSetPaginationSettings}
        currentPage={currentPage}
        totalPages={totalPages}
        currentSize={paginationSettings['size']}
      />
    </div>
  );
};

export default ShipmentsPage;
