import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import CounterpartiesPage from '../pages/counterparty/CounterpartiesPage';
import CounterpartyPage from '../pages/counterparty/CounterpartyPage';
import CounterpartyCreatePage from '../pages/counterparty/CounterpartyCreatePage';
import CounterpartyEditPage from '../pages/counterparty/CounterpartyEditPage';
import OrdersPage from '../pages/order/OrdersPage';
import OrderCreatePage from '../pages/order/OrderCreatePage';
import OrderPage from '../pages/order/OrderPage';
import OrderEditPage from '../pages/order/OrderEditPage';
import ProductsPage from '../pages/product/ProductsPage';
import ReceivedProductsPage from '../pages/product/ReceivedProductsPage';
import ProductCreatePage from '../pages/product/ProductCreatePage';
import ProductPage from '../pages/product/ProductPage';
import ProductEditPage from '../pages/product/ProductEditPage';
import DeliveriesPage from '../pages/delivery/DeliveriesPage';
import DeliveryCreatePage from '../pages/delivery/DeliveryCreatePage';
import DeliveryPage from '../pages/delivery/DeliveryPage';
import DeliveryEditPage from '../pages/delivery/DeliveryEditPage';
import UnidentifiedDeliveryListPage from '../pages/unidentified_delivery/UnidentifiedDeliveriesPage';
import UnidentifiedDeliveryPage from '../pages/unidentified_delivery/UnidentifiedDeliveryPage';
import UnidentifiedDeliveryCreatePage from '../pages/unidentified_delivery/UnidentifiedDeliveryCreatePage';
import UnidentifiedDeliveryEditPage from '../pages/unidentified_delivery/UnidentifiedDeliveryEditPage';
import ShipmentsPage from '../pages/shipment/ShipmentsPage';
import ShipmentPage from '../pages/shipment/ShipmentPage';
import ShipmentScanBarcodePage from '../pages/shipment/ShipmentScanBarcodePage';
import ShipmentCreatePage from '../pages/shipment/ShipmentCreatePage';
import ShipmentEditPage from '../pages/shipment/ShipmentEditPage';
import ShipmentsByInvoiceNumber from '../pages/shipment/ShipmentsByInvoiceNumber';
import CustomerCargoListPage from '../pages/customer_cargo/CustomerCargoListPage';
import CustomerCargoCreatePage from '../pages/customer_cargo/CustomerCargoCreatePage';
import CustomerCargoPage from '../pages/customer_cargo/CustomerCargoPage';
import CustomerCargoEditPage from '../pages/customer_cargo/CustomerCargoEditPage';
import UnidentifiedCargoListPage from '../pages/unidentified_cargo/UnidentifiedCargoListPage';
import UnidentifiedCargoPage from '../pages/unidentified_cargo/UnidentifiedCargoPage';
import UnidentifiedCargoCreatePage from '../pages/unidentified_cargo/UnidentifiedCargoCreatePage';
import UnidentifiedCargoEditPage from '../pages/unidentified_cargo/UnidentifiedCargoEditPage';
import StorehousesPage from '../pages/storehouse/StorehousesPage';
import StorehouseCreatePage from '../pages/storehouse/StorehouseCreatePage';
import StorehousePage from '../pages/storehouse/StorehousePage';
import StorehouseEditPage from '../pages/storehouse/StorehouseEditPage';
import ShelvingsPage from '../pages/shelving/ShelvingsPage';
import ShelvingCreatePage from '../pages/shelving/ShelvingCreatePage';
import ShelvingPage from '../pages/shelving/ShelvingPage';
import ShelvingEditPage from '../pages/shelving/ShelvingEditPage';
import StorageCellsPage from '../pages/storage_cell/StorageCellsPage';
import StorageCellCreatePage from '../pages/storage_cell/StorageCellCreatePage';
import StorageCellPage from '../pages/storage_cell/StorageCellPage';
import StorageCellEditPage from '../pages/storage_cell/StorageCellEditPage';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import { Shield } from '@mui/icons-material';
import UsersPage from "../pages/user/UsersPage";
import UserEditPage from "../pages/user/UserEditForm";

import Bad from '../pages/Bad';

const useRoutes = (setError, resetError, setMsg, resetMsg) => {
  return (
    <Routes>
      <Route path="/" />
      <Route
        path="/not_found"
        element={<Bad />}
      />
      <Route
        path="/login"
        element={<LoginPage />}
      />
      <Route
        path="/register"
        element={<RegisterPage />}
      />
      <Route
        element={
          <PrivateRoute
            section="Counterparties"
            permission="read_all"
          />
        }
      >
        <Route
          path="/counterparties"
          element={<CounterpartiesPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Counterparties"
            permission="read"
          />
        }
      >
        <Route
          path="counterparties/:id"
          element={<CounterpartyPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Counterparties"
            permission="edit"
          />
        }
      >
        <Route
          path="counterparties/:id/edit"
          element={<CounterpartyEditPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Counterparties"
            permission="create"
          />
        }
      >
        <Route
          path="/counterparties/create"
          element={<CounterpartyCreatePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Orders"
            permission="read_all"
          />
        }
      >
        <Route
          path="/orders"
          element={<OrdersPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Orders"
            permission="create"
          />
        }
      >
        <Route
          path="/orders/create"
          element={<OrderCreatePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Orders"
            permission="read"
          />
        }
      >
        <Route
          path="/orders/:id"
          element={<OrderPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Orders"
            permission="edit"
          />
        }
      >
        <Route
          path="/orders/:id/edit"
          element={<OrderEditPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Products"
            permission="read_all"
          />
        }
      >
        <Route
          path="/products"
          element={<ProductsPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Products"
            permission="create"
          />
        }
      >
        <Route
          path="/products/create"
          element={<ProductCreatePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Products"
            permission="read"
          />
        }
      >
        <Route
          path="/products/:id"
          element={<ProductPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Products"
            permission="edit"
          />
        }
      >
        <Route
          path="products/:id/edit"
          element={<ProductEditPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Received products"
            permission="read_all"
          />
        }
      >
        <Route
          path="/received_products"
          element={<ReceivedProductsPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Received products"
            permission="read"
          />
        }
      >
        <Route
          path="/received_products/:id"
          element={<ProductPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Received products"
            permission="edit"
          />
        }
      >
        <Route
          path="/received_products/:id/edit"
          element={<ProductEditPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Deliveries"
            permission="read_all"
          />
        }
      >
        <Route
          path="/deliveries"
          element={<DeliveriesPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Deliveries"
            permission="read"
          />
        }
      >
        <Route
          path="/deliveries/:id"
          element={<DeliveryPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Deliveries"
            permission="create"
          />
        }
      >
        <Route
          path="/deliveries/create"
          element={<DeliveryCreatePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Deliveries"
            permission="edit"
          />
        }
      >
        <Route
          path="/deliveries/:id/edit"
          element={<DeliveryEditPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Unidentified deliveries"
            permission="read_all"
          />
        }
      >
        <Route
          path="/unidentified_deliveries"
          element={<UnidentifiedDeliveryListPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Unidentified deliveries"
            permission="create"
          />
        }
      >
        <Route
          path="/unidentified_deliveries/create"
          element={<UnidentifiedDeliveryCreatePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Unidentified deliveries"
            permission="read"
          />
        }
      >
        <Route
          path="/unidentified_deliveries/:id"
          element={<UnidentifiedDeliveryPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Unidentified deliveries"
            permission="edit"
          />
        }
      >
        <Route
          path="/unidentified_deliveries/:id/edit"
          element={<UnidentifiedDeliveryEditPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Shipments"
            permission="read_all"
          />
        }
      >
        <Route
          path="/shipments"
          element={<ShipmentsPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Shipments"
            permission="scan_barcode" // Под вопросом
          />
        }
      >
        <Route
          path="/shipments/scan_barcode"
          element={<ShipmentScanBarcodePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Shipments"
            permission="by_invoice_number" // Под вопросом
          />
        }
      >
        <Route
          path="/shipments/by_invoice_number"
          element={<ShipmentsByInvoiceNumber />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Shipments"
            permission="read"
          />
        }
      >
        <Route
          path="/shipments/:id"
          element={<ShipmentPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Shipments"
            permission="create"
          />
        }
      >
        <Route
          path="/shipments/create"
          element={<ShipmentCreatePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Shipments"
            permission="edit"
          />
        }
      >
        <Route
          path="shipments/:id/edit"
          element={<ShipmentEditPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Customer cargo list"
            permission="read_all"
          />
        }
      >
        <Route
          path="/customer_cargo"
          element={<CustomerCargoListPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Customer cargo list"
            permission="create"
          />
        }
      >
        <Route
          path="/customer_cargo/create"
          element={<CustomerCargoCreatePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Customer cargo list"
            permission="read"
          />
        }
      >
        <Route
          path="customer_cargo/:id"
          element={<CustomerCargoPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Customer cargo list"
            permission="edit"
          />
        }
      >
        <Route
          path="customer_cargo/:id/edit"
          element={<CustomerCargoEditPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Unidentified cargo list"
            permission="read_all"
          />
        }
      >
        <Route
          path="/unidentified_cargo"
          element={<UnidentifiedCargoListPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Unidentified cargo list"
            permission="create"
          />
        }
      >
        <Route
          path="/unidentified_cargo/create"
          element={<UnidentifiedCargoCreatePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Unidentified cargo list"
            permission="read"
          />
        }
      >
        <Route
          path="unidentified_cargo/:id"
          element={<UnidentifiedCargoPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Unidentified cargo list"
            permission="edit"
          />
        }
      >
        <Route
          path="unidentified_cargo/:id/edit"
          element={<UnidentifiedCargoEditPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Storehouses"
            permission="read_all"
          />
        }
      >
        <Route
          path="/storehouses"
          element={<StorehousesPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Storehouses"
            permission="create"
          />
        }
      >
        <Route
          path="/storehouses/create"
          element={<StorehouseCreatePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Storehouses"
            permission="read"
          />
        }
      >
        <Route
          path="storehouses/:id"
          element={<StorehousePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Storehouses"
            permission="edit"
          />
        }
      >
        <Route
          path="storehouses/:id/edit"
          element={<StorehouseEditPage />}
        />
        {/* <Route
          path="/shelvings"
          element={<ShelvingsPage />}
        /> */}
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Shelvings"
            permission="create"
          />
        }
      >
        <Route
          path="/shelvings/create"
          element={<ShelvingCreatePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Shelvings"
            permission="read"
          />
        }
      >
        <Route
          path="/shelvings/:id"
          element={<ShelvingPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Shelvings"
            permission="edit"
          />
        }
      >
        <Route
          path="shelvings/:id/edit"
          element={<ShelvingEditPage />}
        />
        {/* <Route
          path="/storage_cells"
          element={<StorageCellsPage />}
        /> */}
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Storage cells"
            permission="create"
          />
        }
      >
        <Route
          path="/storage_cells/create"
          element={<StorageCellCreatePage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Storage cells"
            permission="read"
          />
        }
      >
        <Route
          path="/storage_cells/:id"
          element={<StorageCellPage />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute
            section="Storage cells"
            permission="edit"
          />
        }
      >
        <Route
          path="/storage_cells/:id/edit"
          element={<StorageCellEditPage />}
        />
      </Route>
      <Route path="/users" element={<UsersPage />} />
      <Route path="/users/:userId/edit" element={<UserEditPage />} />
    </Routes>
  );
};

export default useRoutes;
